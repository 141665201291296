import React, { useCallback, useEffect, useRef, useState } from 'react';

import styles from './Logo.module.css';

const WIDTH = 210;
const COLORS = ['blue', 'red', 'yellow', 'orange', 'green', 'purple'];

const useRefState = (initialValue) => {
  const [state, setState] = useState(initialValue);
  const stateRef = useRef(state);

  useEffect(() => {
    stateRef.current = state;
  }, [state]);
  return [state, stateRef, setState];
};

const isBrowser = typeof window !== 'undefined';

const Logo = () => {
  const [currentColor, currentColorRef, setCurrentColor] = useRefState(0);
  const [currentX, setCurrentX] = useState(0);
  const [currentY, setCurrentY] = useState(0);

  const xDirectionRef = useRef(1);
  const yDirectionRef = useRef(1);
  const logoRef = useRef();
  const requestRef = useRef();
  const previousTimeRef = useRef();
  const windowWidth = isBrowser && window.innerWidth;
  const windowHeight = isBrowser && window.innerHeight;

  const handleColorChange = useCallback(() => {
    if (currentColorRef.current === COLORS.length - 1) {
      setCurrentColor(0);
    } else {
      setCurrentColor((prevColor) => prevColor + 1);
    }
  }, [currentColorRef, setCurrentColor]);

  const animate = useCallback(
    (time) => {
      let logoRect = null;
      let logoLeft = null;
      let logoTop = null;

      if (previousTimeRef.current !== undefined) {
        const deltaTime = time - previousTimeRef.current;

        // Pass on a function to the setter of the state
        // to make sure we always have the latest state
        if (logoRef?.current) {
          logoRect = logoRef.current.getBoundingClientRect();
          logoLeft = parseInt(logoRef.current.style.left, 10);
          logoTop = parseInt(logoRef.current.style.top, 10);

          // Collisions

          // Check left and right collision
          if (
            logoLeft >= windowWidth - logoRect.width &&
            xDirectionRef.current === 1
          ) {
            xDirectionRef.current = -1;
            handleColorChange();
          } else if (logoLeft <= 0 && xDirectionRef.current === -1) {
            xDirectionRef.current = 1;
            handleColorChange();
          }

          // Check top and bottom collision
          if (
            logoTop >= windowHeight - logoRect.height &&
            yDirectionRef.current === 1
          ) {
            yDirectionRef.current = -1;
            handleColorChange();
          } else if (logoTop <= 0 && yDirectionRef.current === -1) {
            yDirectionRef.current = 1;
            handleColorChange();
          }
          // }

          setCurrentX(
            (prevX) => prevX + deltaTime * 0.1 * xDirectionRef.current,
          );
          setCurrentY(
            (prevY) => prevY + deltaTime * 0.1 * yDirectionRef.current,
          );
        }
      }
      previousTimeRef.current = time;
      requestRef.current = requestAnimationFrame(animate);
    },
    [
      handleColorChange,
      windowWidth,
      windowHeight,
      xDirectionRef,
      yDirectionRef,
    ],
  );

  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current);
  }, [animate]);

  return (
    <svg
      viewBox="0 0 210 107"
      xmlns="http://www.w3.org/2000/svg"
      fill={COLORS[currentColor]}
      style={{
        width: `${WIDTH}px`,
        left: `${currentX}px`,
        top: `${currentY}px`,
      }}
      ref={logoRef}
      className={styles.root}
    >
      <path d="m118.895 20.346s-13.743 16.922-13.04 18.001c.975-1.079-4.934-18.186-4.934-18.186s-1.233-3.597-5.102-15.387h-14.009-33.998-25.637l-2.56 11.068h19.299 4.579c12.415 0 19.995 5.132 17.878 14.225-2.287 9.901-13.123 14.128-24.665 14.128h-4.316l5.552-24.208h-19.295l-8.192 35.368h27.398c20.612 0 40.166-11.067 43.692-25.288.617-2.614.53-9.185-1.054-13.053 0-.093-.091-.271-.178-.537-.087-.093-.178-.722.178-.814.172-.092.525.271.525.358 0 0 .179.456.351.813l17.44 50.315 44.404-51.216 18.761-.092h4.579c12.424 0 20.09 5.132 17.969 14.225-2.29 9.901-13.205 14.128-24.75 14.128h-4.405l5.635-24.207h-19.287l-8.198 35.368h27.398c20.611 0 40.343-11.067 43.604-25.288 3.347-14.225-11.101-25.293-31.89-25.293h-18.143-22.727c-10.834 13.049-12.862 15.572-12.862 15.572z" />
      <path d="m99.424 67.329c-52.143 0-94.424 6.12-94.424 13.683 0 7.558 42.281 13.678 94.424 13.678 52.239 0 94.524-6.12 94.524-13.678.001-7.563-42.284-13.683-94.524-13.683zm-3.346 18.544c-11.98 0-21.58-2.072-21.58-4.595s9.599-4.59 21.58-4.59c11.888 0 21.498 2.066 21.498 4.59 0 2.523-9.61 4.595-21.498 4.595z" />
      <path d="m182.843 94.635v-.982h-5.745l-.239.982h2.392l-.965 7.591h1.204l.955-7.591z" />
      <path d="m191.453 102.226v-8.573h-.949l-3.12 5.881-1.416-5.881h-.955l-2.653 8.573h.977l2.138-6.609 1.442 6.609 3.359-6.609.228 6.609z" />
    </svg>
  );
};

export default Logo;
